@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import '../../node_modules/jquery-ui-dist/jquery-ui.min.css';
@import "./common/spinner";
@import "./common/password-reset";

.min-w-ios {
  min-width: 320px;
}

.link-no-style {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: none;
    outline: inherit;
    box-shadow: inherit;
    padding: inherit;
    vertical-align: inherit;
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
